const stickers = [
  {
    name: 'Shitty Logo',
    category: 'Shitty Kitties',
    image: '/memes/stickers/sk-logo-colored.png',
    gens: [1, 2, 3, 4],
    startingPosition: {
      width: 716,
      height: 491,
      x: 2200,
      y: 2400,
      rotation: 0,
    },
  },
  {
    name: `SHEP Hat`,
    category: 'SHEP',
    image: '/memes/stickers/collabs/shep/shep-hat.png',
    disableHead: true,
    gens: [1, 2, 3, 4],
    startingPosition: {
      width: 1008,
      height: 384,
      x: 550,
      y: 250,
      rotation: 30,
    },
  },
  {
    name: `MEEP Bubble`,
    category: 'SHEP',
    image: '/memes/stickers/collabs/shep/meep-bottom-left.png',
    gens: [1, 2, 3, 4],
    startingPosition: {
      width: 880,
      height: 440,
      x: 1950,
      y: 100,
      rotation: 0,
    },
  },
  {
    name: `SHEP Eyes`,
    category: 'SHEP',
    image: '/memes/stickers/collabs/shep/shep-eyes.png',
    disableEyes: true,
    gens: [1, 2, 3, 4],
    startingPosition: {
      width: 640,
      height: 200,
      x: 1100,
      y: 750,
    },
  },

  {
    name: 'BALLSACK V1',
    category: 'BALLSACK',
    image: '/memes/stickers/collabs/ballsack/ballsack-v1.png',
    gens: [1, 2, 3, 4],
    startingPosition: {
      width: 1500,
      height: 1500,
      x: 0,
      y: 1800,
    },
  },
  {
    name: 'BALLSACK V2',
    category: 'BALLSACK',
    image: '/memes/stickers/collabs/ballsack/ballsack-v2.png',
    gens: [1, 2, 3, 4],
    startingPosition: {
      width: 1000,
      height: 1000,
      x: 1200,
      y: 1900,
    },
  },
];

export default stickers;
