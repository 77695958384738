export const ogCityImageUrl = '/builder/og-city-client.png';

const cityUpgrades = [
  {
    name: 'Basic',
    treatsCost: 5000,
    algoCost: 40,
    limit: 500,
    populationIncrease: 4,
    imageUrlClient: '/builder/basic-city-client.png',
    imageUrlServer: '/builder/basic-city-server.png',
  },
  {
    name: 'Island',
    treatsCost: 12000,
    algoCost: 69,
    limit: 50,
    populationIncrease: 6,
    imageUrlClient: '/builder/island-city-client.png',
    imageUrlServer: '/builder/island-city-server.png',
    requirements: {
      blocks: ['Shitty Gully Lagoon', 'Lundi Beach Club', 'Lake', 'Beach', 'Lighthouse', 'Old Cave Cave', 'Water Park'],
      traits: ['Legendary', 'Muscle Suit', 'Board Shorts', 'Grass Skirt', 'Pool Float', 'Coconut Bra', 'Bikini', 'Pirate Clothes', 'Pirate Hat'],
    },
  },
  {
    name: 'Ocean',
    treatsCost: 12000,
    algoCost: 69,
    limit: 50,
    populationIncrease: 6,
    imageUrlClient: '/builder/ocean-city-client.png',
    imageUrlServer: '/builder/ocean-city-server.png',
    requirements: {
      blocks: ['Shitty Gully Lagoon', 'Lundi Beach Club', 'Lake', 'Aquarium', 'Beach', 'Water Park'],
      traits: ['Legendary', 'Fish Bowl', 'Water', 'Jellyfish', 'Octopus', 'Fishy Shirt', 'Fish Nets', 'Fish'],
    },
  },
  {
    name: 'Jungle',
    treatsCost: 12000,
    algoCost: 69,
    limit: 50,
    populationIncrease: 6,
    imageUrlClient: '/builder/jungle-city-client.png',
    imageUrlServer: '/builder/jungle-city-server.png',
    requirements: {
      blocks: ['Heepow Watering Hole', 'Golden Goot Temple', 'Ropes Course', 'Old Cave Cave', 'Tree House', 'Hippy Forest'],
      traits: ['Legendary', 'Banana Hat', 'Banana Costume', 'Treasure Map', 'Wide Brim Hat', 'Banana', 'Camo'],
    },
  },

  {
    name: 'Arctic',
    treatsCost: 12000,
    algoCost: 69,
    limit: 50,
    populationIncrease: 6,
    imageUrlClient: '/builder/arctic-city-client.png',
    imageUrlServer: '/builder/arctic-city-server.png',
    requirements: {
      blocks: [
        'NOOT Lake',
        'Snowman Village',
        'Snowy Gray House',
        'Snowy Yellow House',
        'Town Christmas Tree',
        'North Pole',
        'Frozen Pond',
        'Igloo',
        'Ski Resort',
        'Winter Resort',
      ],
      traits: [
        'Legendary',
        'Scarf And Gloves',
        'Faux Fur Coat',
        'Puffy Winter Coat',
        'Skis',
        'Snow Hat',
        'Ice Crown',
        'Snowflake Headband',
        'Penguin',
        'Beanie',
        'Ice',
        'Santa Hat',
        'Santa Suit',
      ],
    },
  },
  {
    name: 'Wasteland',
    treatsCost: 12000,
    algoCost: 69,
    limit: 50,
    populationIncrease: 6,
    imageUrlClient: '/builder/wasteland-city-client.png',
    imageUrlServer: '/builder/wasteland-city-server.png',
    requirements: {
      blocks: [
        'Zombie Apocalypse',
        'Mutants Encounter',
        'Desert',
        'Volcano',
        'Nuclear Power Plant',
        'Slime River',
        'Asteroids',

        'Mortality Wasteland',
      ],
      traits: ['Legendary', 'Zombie', 'Atomic', 'Slime', 'Slime Bucket', 'Stitched'],
    },
  },
  {
    name: 'Monsters',
    treatsCost: 12000,
    algoCost: 69,
    limit: 50,
    populationIncrease: 8,
    imageUrlClient: '/builder/monster-city-client.png',
    imageUrlServer: '/builder/monster-city-server.png',
    requirements: {
      blocks: ['Mutants Encounter', 'BLOP Pods', 'Marvin Attacks!', 'Mt. Moon Dragon', 'Haunted House', 'Petting Zoo', 'The Hole'],
      traits: ['Legendary', 'Tentacles', 'Mummy', 'Devil Horns', 'Devil', 'Octopus', 'Stitched', 'Zombie', 'Bat Wings', 'Cyclops'],
    },
  },
  {
    name: 'Biodome',
    treatsCost: 25000,
    algoCost: 69,
    limit: 10,
    populationIncrease: 8,
    imageUrlClient: '/builder/biodome-city-client.png',
    imageUrlServer: '/builder/biodome-city-server.png',
    requirements: {
      blocks: ['Wormhole', 'Moon Landing', 'Moon Dome', 'The Encounter', 'Space Dimension'],
      traits: ['Space Helmet', 'Space Suit', 'Alien', 'Alien Abduction', 'Galaxy', 'Encounter', 'Mushroom', 'Mushroom Hat', 'Daisy'],
    },
  },
  {
    name: 'Space Station',
    treatsCost: 25000,
    algoCost: 69,
    limit: 10,
    populationIncrease: 8,
    imageUrlClient: '/builder/space-city-client.png',
    imageUrlServer: '/builder/space-city-server.png',
    requirements: {
      blocks: ['Wormhole', 'Moon Landing', 'Moon Dome', 'The Encounter', 'Space Dimension'],
      traits: ['Space Helmet', 'Space Suit', 'Alien', 'Alien Abduction', 'Galaxy', 'Encounter'],
    },
  },
  {
    name: 'Acid Overload',
    treatsCost: 25000,
    algoCost: 69,
    limit: 10,
    populationIncrease: 8,
    imageUrlClient: '/builder/acid-city-client.png',
    imageUrlServer: '/builder/acid-city-server.png',
    requirements: {
      blocks: ['Mushroom House', 'Hippy Forest', 'Bubblegum Land', 'Shroom Dimension'],
      traits: ['Acid', 'Rainbow', 'Psychedelic', 'Acid Kitten Hoody'],
    },
  },
];

export default cityUpgrades;
