const zappyAssets = [
  2588544715, 2588552401, 2588552591, 2588552676, 2588552788, 2588553006, 2588553516, 2588553757, 2588553928, 2588610746, 2588620969, 2588621886,
  2588622080, 2588622269, 2588622490, 2588622776, 2588623384, 2588623607, 2588623678, 2588623987, 2588624155, 2588624281, 2588624435, 2588624735,
  2588624910, 2588625984, 2588626489, 2588626946, 2588627171, 2588627325, 2588627601, 2588627892, 2588628267, 2588628679, 2588629051, 2588629202,
  2588629416, 2588629916, 2588630056, 2588630504, 2588630727, 2588630896, 2588631179, 2588631391, 2588631541, 2588631806, 2588632265, 2588632499,
  2588632712, 2588632835, 2588633262, 2588633472, 2588633873, 2588634225, 2588634887, 2588636010, 2588636573, 2588636955, 2588637270, 2588637409,
  2588637851, 2588638752, 2588639641, 2588640906, 2588641845, 2588643092, 2588644390, 2588644931, 2588645135, 2588645365, 2588645734, 2588645882,
  2588646148, 2588646548, 2588646821, 2588647246, 2588647423, 2588648130, 2588648706, 2588648855, 2588649583, 2588650499, 2588650706, 2588651716,
  2588653654, 2588654224, 2588656431, 2588656655, 2588656972, 2588657434, 2588657778, 2588657902, 2588658154, 2588658364, 2588658968, 2588659850,
  2588660123, 2588660310, 2588660521, 2588661089, 2588661162, 2588661772, 2588662410, 2588662509, 2588662869, 2588663179, 2588663958, 2588664891,
  2588665722, 2588666306, 2588666877, 2588667135, 2588667533, 2588667725, 2588667943, 2588668375, 2588668760, 2588668810, 2588669008, 2588669407,
  2588669520, 2588669704, 2588670188, 2588670791, 2588670980, 2588671282, 2588671410, 2588671550, 2588671830, 2588672323, 2588672649, 2588672876,
  2588673165, 2588673621, 2588674691, 2588675474, 2588676035, 2588676667, 2588676894, 2588677085, 2588677412, 2588677571, 2588677804, 2588678336,
  2588678410, 2588678665, 2588679074, 2588679616, 2588680630, 2588681309, 2588681481, 2588681777, 2588682005, 2588682205, 2588682912, 2588683081,
  2588683514, 2588683777, 2588683975, 2588684232, 2588684535, 2588684675, 2588684875, 2588685037, 2588685734, 2588687482, 2588687983, 2588688314,
  2588688483, 2588689428, 2588689940, 2588690080, 2588690539, 2588690773, 2588691166, 2601204551, 2601226303, 2601327528, 2601343919, 2601343995,
  2601344818, 2601345089, 2601345286, 2601345732, 2601345898, 2601346333, 2601346559, 2601346999, 2601347385, 2601347547, 2601347726, 2601347878,
  2601348341, 2601348501, 2601348701, 2601349138, 2601349345, 2601349576, 2601408613, 2601408749, 2601408785, 2601408867, 2601408893, 2601409256,
  2601409264, 2601409334, 2601409434, 2601409489, 2601409526, 2601409625, 2601409701, 2601410091, 2601410205, 2601410314, 2601410315, 2601410370,
  2601410416, 2601410460, 2601410465, 2601410784, 2601410809, 2601410916, 2601411202, 2601411817, 2601411859, 2601412029, 2601412061, 2601412192,
  2601412214, 2601412559, 2601412580, 2601412803, 2601412818, 2601412889, 2601412923, 2601412925, 2601413265, 2601413273, 2601914620, 2614743551,
  2614744348, 2614745301, 2614745440, 2614745575, 2614745727, 2614750352, 2614750662, 2614750929, 2614751152, 2614751230, 2614876821, 2614877180,
  2614877444, 2614881070, 2614881391, 2614881526, 2614882408, 2614882746, 2614882895, 2614883289, 2614883487, 2614883862, 2614883998, 2614884126,
  2614884316, 2614884488, 2614884665, 2614885005, 2614885108, 2614885374, 2614885472, 2614885634, 2614885957, 2614901607, 2614901732, 2614901830,
  2614902484, 2614902708, 2614902986, 2614903249, 2614903400, 2614903510, 2614903597, 2614903685, 2614904108, 2614904324, 2614904401, 2614904541,
  2614904835, 2614904915, 2614905301, 2614905455, 2614905623, 2614907262, 2614907439, 2614907648, 2614907781, 2614907951, 2614908125, 2614908274,
  2614908533, 2614908687, 2614908966, 2614909110, 2614909647, 2614909790, 2614909943, 2614910652, 2614911214, 2614911496, 2614911683, 2614911831,
  2614911845, 2614911938, 2614912018, 2614912097, 2614912167, 2614912265, 2614912312, 2614912521, 2614912588, 2614912682, 2614912771, 2614913236,
  2614913319, 2614913428, 2614913470, 2614913716, 2614913752, 2614913825, 2614913905, 2614914069, 2614914193, 2614914289, 2614914480, 2614974935,
  2614975114, 2615311036, 2615427860, 2615428451, 2615428726, 2615429057, 2615429196, 2615430116, 2615432364, 2615457606, 2615458022, 2615458180,
  2615458311, 2615458656, 2615458752, 2615458882, 2615459156, 2615459187, 2615459571, 2615459761, 2615459837, 2615460366, 2615461069, 2615461204,
  2615461572, 2615461777, 2615461933, 2615462602, 2615462823, 2615462965, 2615521008, 2615521096, 2615527077, 2615527162, 2615527339, 2615527451,
  2615527729, 2615527852, 2615528116, 2615530582, 2615531394, 2615533489, 2615534505, 2615534707, 2615535325, 2615535934, 2615537528, 2615537914,
  2615538673, 2615539015, 2615539548, 2615540958, 2615553734, 2615553857, 2615558245, 2615558405, 2615558573, 2615558746, 2615559157, 2615559326,
  2615585861, 2615585949, 2615586305, 2615586414, 2615586615, 2615586723, 2615586883, 2615587044, 2615587311, 2615587415, 2615587496, 2615587999,
  2615588216, 2615588364, 2615588794, 2615589271, 2615589752, 2615589873, 2615590311, 2615590476, 2615638545, 2615638943, 2615639149, 2615639411,
  2615639562, 2615639852, 2615640023, 2615640256, 2615641323, 2615641988, 2615642453, 2615642648, 2615642665, 2615642832, 2615642840, 2615643079,
  2615643117, 2615643697, 2615643844, 2615643914, 2615643979, 2615644107, 2615644553, 2615644754, 2615644781, 2615644957, 2615645009, 2615645104,
  2615645404, 2615645602, 2615646215, 2615646395, 2615646792, 2615646997, 2615647220, 2615647758, 2615647937, 2615648192, 2615648446, 2615649044,
  2615686593, 2615686733, 2615687049, 2615687322, 2615687432, 2615687612, 2615687682, 2615687888, 2615688046, 2615688257, 2615688378, 2615688616,
  2615688778, 2615689208, 2615689413, 2615689755, 2615689958, 2615690078, 2615690190, 2615690427, 2615707849, 2615708084, 2615709318, 2615709531,
  2615714501, 2615799442, 2615799521, 2615799598, 2615872536, 2615872699, 2615873215, 2615873376, 2615874055, 2615874254, 2615874403, 2615874600,
  2615874993, 2615875305, 2615875499, 2615875745, 2615895559, 2615895677, 2615896064, 2615903471, 2615903609, 2615903811, 2615903929, 2615904050,
  2615913108, 2615913345, 2615913944, 2615914048, 2615914133, 2615914558, 2615914988, 2615915120, 2615915255, 2616110901, 2616125724, 2616125831,
  2616125953, 2616126084, 2616126252, 2616126707, 2616127221, 2616127370, 2616127748, 2616127966, 2616128772, 2616129142, 2616129340, 2616129569,
  2616129779, 2616129875, 2616129999, 2616130337, 2616130591, 2616130763, 2616151476, 2616152527, 2616152660, 2616153050, 2616153565, 2616153930,
  2616154188, 2616154974, 2616156222, 2616156753, 2616156951, 2616157202, 2616157445, 2616157609, 2616157903, 2616158024, 2616158944, 2616159381,
  2616159465, 2616159720, 2616183643, 2616184447, 2616185214, 2616186150, 2616187251, 2616188290, 2616189736, 2616192035, 2616192420, 2616192833,
  2616193044, 2616194053, 2616194534, 2616195123, 2616195702, 2616195897, 2616196126, 2616196286, 2616196425, 2616196681, 2616209911, 2616211731,
  2616212632, 2616212968, 2616214167, 2616214653, 2616214994, 2616215331, 2616215710, 2616216041, 2616216242, 2616217014, 2616217139, 2616217326,
  2616217448, 2616217685, 2616218118, 2616218387, 2616218745, 2616219179, 2616219725, 2616219939, 2616220077, 2616220230, 2616220558, 2616220910,
  2616221087, 2616221882, 2616222148, 2616222380, 2616222477, 2616222610, 2616223089, 2616223217, 2616223449, 2616223604, 2616351245, 2616351388,
  2616351557, 2616352117, 2616428300, 2616428493, 2616428690, 2616428950, 2616429077, 2616444158, 2616444552, 2616483213, 2616582682, 2616582891,
  2616623586, 2616623659, 2616623796, 2616624054, 2616624197, 2616624451, 2616624584, 2616624759, 2616624897, 2616625141, 2616625254, 2616625405,
  2616625534, 2616625636, 2616625758, 2616625897, 2616626033, 2616626229, 2616626415, 2616626637, 2616664702, 2616665295, 2616665573, 2616666025,
  2616667116, 2616667496, 2616668043, 2616668327, 2616668830, 2616669129, 2616670238, 2616670821, 2616671024, 2616671307, 2616671475, 2616671874,
  2616672852, 2616673554, 2616673812, 2616673964, 2616697051, 2616888810, 2616900137, 2617059061, 2617063694, 2617063746, 2617063786, 2617063976,
  2617064072, 2617064184, 2617064383, 2617064464, 2617064568, 2617064712, 2617064841, 2617064931, 2617064984, 2617065085, 2619373420, 2621020205,
  2621020394, 2621020543, 2621021033, 2621021226, 2621035442, 2621035630, 2621035777, 2621035912, 2621036100, 2621043106, 2621043180, 2621043331,
  2621043428, 2621043534, 2621049239, 2621049329, 2621049863, 2621049956, 2621050100, 2621089945, 2621090104, 2621090228, 2621090397, 2621090597,
  2621254985, 2621463320, 2621475505, 2621475807, 2621475857, 2621476452, 2621476462, 2621476918, 2621476966, 2621476975, 2621477057, 2621477349,
  2621477486, 2621477516, 2621477583, 2621477937, 2621477944, 2621478372, 2621478374, 2621478767, 2621478773, 2621479013, 2621479316, 2621479407,
  2621479449, 2621479738, 2621479793, 2621479931, 2621479972, 2621480289, 2621480322, 2621480399, 2621480687, 2621480721, 2621480775, 2621481091,
  2621481150, 2621481200, 2621481214, 2621481531, 2621481585, 2621481714, 2621494425, 2621494798, 2621495267, 2621495586, 2621496063, 2621496593,
  2621496963, 2621496996, 2621497072, 2621497168, 2621497592, 2621497709, 2621497717, 2621498067, 2621498331, 2621498639, 2621498732, 2621498871,
  2621498914, 2621499014, 2621499368, 2621499607, 2621499748, 2621499892, 2621499986, 2621500076, 2621500150, 2621500191, 2621500208, 2621500424,
  2621500472, 2621500616, 2621500677, 2621500873, 2621500928, 2621501975, 2621502199, 2621502560, 2621502959, 2621503224, 2621516660, 2621516812,
  2621516917, 2621517035, 2621517204, 2621517298, 2621517555, 2621517914, 2621518100, 2621518444, 2621518587, 2621518955, 2621519492, 2621519675,
  2621520074, 2621520176, 2621520587, 2621520608, 2621521364, 2621521379, 2621521537, 2621521543, 2621522055, 2621522061, 2621522205, 2621522583,
  2621522947, 2621523064, 2621523447, 2621523948, 2621524352, 2621526590, 2621527661, 2621528189, 2621528322, 2621528728, 2621529127, 2621552363,
  2621552482, 2621552605, 2621552761, 2621553516, 2621553726, 2621554247, 2621554478, 2621554757, 2621555103, 2621555291, 2621555422, 2621555550,
  2621555831, 2621556020, 2621556133, 2621556234, 2621556337, 2621556411, 2621556574, 2621580380, 2621580482, 2621580631, 2621580922, 2621581051,
  2621581232, 2621581835, 2621582092, 2621582203, 2621582306, 2621582605, 2621582696, 2621582816, 2621583402, 2621583519, 2621583722, 2621583814,
  2621583906, 2621584009, 2621584256, 2621591289, 2621591341, 2621591437, 2621591482, 2621591647, 2621591830, 2622004139, 2622004651, 2622004826,
  2622004956, 2622005128, 2622005306, 2622005457, 2622005615, 2622005995, 2622006322, 2622006741, 2622006911, 2622007322, 2622007467, 2622077520,
  2622173358, 2622173736, 2622173912, 2622174002, 2622174163, 2622174286, 2622174848, 2622175032, 2622175097, 2622175332, 2622175799, 2622176149,
  2622176345, 2622177175, 2622177525, 2622177789, 2622178533, 2622178701, 2622179041, 2622179357, 2622181863, 2622181884, 2622181990, 2622182250,
  2622182777, 2622182803, 2622182999, 2622189665, 2622189896, 2622190271, 2622190443, 2622190911, 2622225649, 2622226042, 2622226407, 2622226784,
  2622227141, 2622235024, 2622235106, 2622254835, 2622255161, 2622256215, 2622257569, 2622258129, 2622258264, 2622258781, 2622258950, 2622424887,
  2622424993, 2622425325, 2622425725, 2622425848, 2622426198, 2622426288, 2622427115, 2622427655, 2622427747, 2622428073, 2622428339, 2622428791,
  2622429171, 2622429271, 2622430069, 2622430234, 2622430662, 2622431026, 2622431182, 2622445554, 2622445662, 2622446233, 2622446672, 2622446803,
  2622447193, 2622532412, 2622532475, 2622532820, 2622532960, 2622533344, 2622533472, 2622534148, 2622534574, 2622534788, 2622535177, 2622535283,
  2622535683, 2622536077, 2623078402, 2623078785, 2623078933, 2623079308, 2623094110, 2623094212, 2623729792, 2623730203, 2623730545, 2623730619,
  2623730921, 2623731159, 2623731481, 2623731813, 2623731996, 2623732325, 2623732541, 2623732876, 2623733210, 2623733395, 2623733920, 2623734032,
  2623734455, 2623734782, 2623734840, 2623735187, 2623749327, 2623751721, 2623751871, 2623752261, 2623752619, 2623753393, 2623754039, 2623754325,
  2623754775, 2623754907, 2623755281, 2623755337, 2623755801, 2623756379, 2623756442, 2623756902, 2623757093, 2623757514, 2623757677, 2623757988,
  2624313435, 2624313781, 2624313988, 2624318352, 2624320361, 2624322750, 2624324227, 2624324994, 2624325535, 2624327238, 2624328065, 2624328339,
  2624332533, 2624333951, 2624334451, 2624583957, 2624584149, 2624584261, 2624584387, 2624831443, 2624831484, 2624831789, 2624831879, 2624832195,
  2624832502, 2624832682, 2624833063, 2624833154, 2624833488, 2624833824, 2624833891, 2624834235, 2624834325, 2624834759, 2624835085, 2624835122,
  2624835478, 2624835576, 2624836553, 2624849592, 2624849853, 2624850278, 2624850404, 2624850758, 2624851449, 2624852184, 2624852311, 2624852713,
  2624853281, 2624853377, 2624853728, 2624853879, 2624854378, 2624854710, 2624854840, 2624855300, 2624855376, 2624855729, 2624856105, 2624880626,
  2624880982, 2624972941, 2625009606, 2625009896, 2625010359, 2625010531, 2625010793, 2625010917, 2625011053, 2625011165, 2625011296, 2625011465,
  2625011768, 2625011915, 2625012033, 2625012360, 2625012503, 2625012673, 2625012719, 2625012900, 2625013019, 2625013411, 2625019815, 2625020077,
  2625020315, 2625022169, 2625022788, 2625023154, 2625023385, 2625023566, 2625023770, 2625024048, 2625024240, 2625024807, 2625025097, 2625025394,
  2625025772, 2625026482, 2625026685, 2625027401, 2625027639, 2625027742, 2625035156, 2625035626, 2625035853, 2625035942, 2625036165, 2625036347,
  2625036579, 2625036738, 2625036919, 2625037609, 2625037999, 2625038605, 2625039009, 2625039370, 2625039601, 2625039854, 2625040107, 2625040502,
  2625040749, 2625040871, 2625059946, 2625060097, 2625060167, 2625060262, 2625060553, 2625060719, 2625060935, 2625061153, 2625061279, 2625061414,
  2625061520, 2625061723, 2625061887, 2625062029, 2625062240, 2625062322, 2625062402, 2625062494, 2625062606, 2625062785, 2625103711, 2625103894,
  2625103950, 2625104033, 2625104128, 2625104320, 2625104444, 2625104690, 2625104821, 2625104921, 2625105271, 2625105504, 2625105602, 2625105705,
  2625105793, 2625105989, 2625106272, 2625106391, 2625106454, 2625106583, 2625112915, 2625113081, 2625113260, 2625113676, 2625113774, 2625113889,
  2625114020, 2625114295, 2625114417, 2625114554, 2625114611, 2625114746, 2625114863, 2625114907, 2625115046, 2625115498, 2625115582, 2625115761,
  2625115868, 2625116056, 2625128849, 2625128934, 2625129055, 2625129255, 2625129363, 2625129502, 2625130037, 2625130185, 2625130320, 2625130465,
  2625130673, 2625130816, 2625131396, 2625131559, 2625131680, 2625131832, 2625131955, 2625132132, 2625132202, 2625132620, 2625145731, 2625145820,
  2625146437, 2625632700, 2625632801, 2625633235, 2625633305, 2625633665, 2625649829, 2625650011, 2625650087, 2625650217, 2625650339, 2625650477,
  2625650587, 2625651085, 2625651236, 2625651327, 2625651423, 2625651899, 2625652157, 2625652459, 2625652609, 2625652727, 2625652869, 2625652966,
  2625653179, 2625653338, 2625679760, 2625845748, 2625916490, 2625916541, 2626678151, 2626678293, 2626678572, 2626678715, 2626727908, 2626728057,
  2626728134, 2626728505, 2626772779, 2626772920, 2626773316, 2626779130, 2626779217, 2626803688, 2626803872, 2626804003, 2626804318, 2626805384,
  2626835707, 2626835961, 2626836058, 2626836179, 2626855698, 2626881459, 2626946941, 2626947072, 2626947319, 2626947705, 2626947816, 2626967908,
  2626981225, 2626981334, 2627007853, 2627008401, 2627081763, 2627095557, 2627130066, 2627130375, 2627130714, 2627131445, 2627131714, 2627131854,
  2627132330, 2627233947, 2627234087, 2627234224, 2627279796, 2627280242, 2627280357, 2627280759, 2627408243, 2627423601, 2627423730, 2627423870,
  2627424015, 2627424546, 2627424734, 2628242275, 2628243029, 2628243559, 2628243659, 2628244045, 2628244275, 2628244622, 2628244802, 2628245164,
  2628245736, 2628246162, 2628246531, 2628246673, 2628247046, 2628247179, 2628247535, 2628248000, 2628248349, 2628248936, 2628249184, 2628277488,
  2628693714, 2628693857, 2628694038, 2629461421, 2629461686, 2629461755, 2629462055, 2629477500, 2629477540, 2629477587, 2629477901, 2629478226,
  2629478366, 2629478695, 2629478755, 2629479086, 2629479394, 2629479495, 2629479796, 2629479875, 2629480226, 2629480448, 2629480820, 2629481256,
  2631028477, 2631029121, 2631029832, 2631030595, 2631031386, 2631032022, 2631032494, 2631033450, 2631033891, 2631034880, 2631035537, 2631036226,
  2631037043, 2631037872, 2631038612, 2631039027, 2631039328, 2631039621, 2631040151, 2631041525, 2631418462, 2631418658, 2631418788, 2631419091,
  2631419259, 2631427542, 2631427597, 2631427696, 2631427755, 2631428733, 2631435047, 2631435174, 2631435328, 2631435729, 2631435864, 2631443771,
  2631443854, 2631443921, 2631443992, 2631444302, 2631449203, 2638755604, 2638755795, 2638756370, 2638760141, 2638760220, 2638760360, 2643171514,
  2643178751, 2643178813, 2643178996, 2643179087, 2643179250, 2643179492, 2643179543, 2643179717, 2643179774, 2643179954, 2643180040, 2643180112,
  2643180205, 2643180406, 2643204010, 2643204145, 2643427016, 2643427202, 2643427397, 2643427561, 2643427649, 2643440889, 2643441010, 2643441118,
  2643522385, 2643522457, 2643522825, 2643523547, 2643887591, 2643983379, 2644034758, 2644039660, 2644039924, 2644040363, 2644040786, 2644041112,
  2644041192, 2644041549, 2644041659, 2644041968, 2644042218, 2644042337, 2644042639, 2644045698, 2644045782, 2645163571, 2645163663, 2645163760,
  2645163841, 2645163940, 2645163989, 2645164545, 2645164650, 2647684790, 2648271086, 2648271199, 2648271317, 2648271536, 2648271723, 2648271861,
  2648271913, 2648271989, 2648333711, 2648535507, 2648940225, 2648940765, 2648940861, 2648940973, 2648941061, 2648941152, 2652796735, 2654367193,
  2655370963, 2655389933, 2655390462, 2655390517, 2655390638, 2655390943, 2655397887, 2655398071, 2655398333, 2655398488, 2655398579, 2655398687,
  2655421006, 2655421117, 2655421234, 2655421461, 2655421774, 2655464902, 2655477600, 2655491883, 2655505111, 2655505235, 2655505342, 2655505497,
  2655513507, 2655513693, 2655513864, 2655514033, 2655514134, 2655613937, 2655614082, 2655614221, 2655695934, 2655696062, 2655696231, 2655696351,
  2655769360, 2655769566, 2655769838, 2655769961, 2656045222, 2656788165, 2658041193, 2659258903,
];

export default zappyAssets;
