import birthdayMemes from './memes/birthday';
import newYearsMemes from './memes/new-years';

const MEMES = [
  {
    name: 'Custom',
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/custom.png',
    gens: [1, 2, 3, 4],
  },
  {
    name: 'Head Only',
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/head-only.png',
    gens: [4],
  },
  {
    name: `GM Flag`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/flag-gm.png',
    disableAccessory: true,
    gens: [4],
    position1: {
      width: 5000,
      height: 5000,
      x: -1700,
      y: 100,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `GN Flag`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/flag-gn.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `LOL Flag`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/flag-lol.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `WEN Flag`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/flag-wen.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `WOW Flag`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/flag-wow.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Algorand Flag`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/flag-algorand.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `GM Flag`,
    category: 'Shitty Kitties',
    image: '/memes/first-litter/flag-gm.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `GN Flag`,
    category: 'Shitty Kitties',
    image: '/memes/first-litter/flag-gn.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `LOL Flag`,
    category: 'Shitty Kitties',
    image: '/memes/first-litter/flag-lol.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `WEN Flag`,
    category: 'Shitty Kitties',
    image: '/memes/first-litter/flag-wen.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `WOW Flag`,
    category: 'Shitty Kitties',
    image: '/memes/first-litter/flag-wow.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Algorand Flag`,
    category: 'Shitty Kitties',
    image: '/memes/first-litter/flag-algorand.png',
    disableAccessory: true,
    gens: [1],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Speech Bubble`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/bubble-blank.png',
    gens: [1, 2, 3, 4],
    isSpeechBubble: true,
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -100,
    },
    position2: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
    editable: {
      defaultFontSize: 150,
      fontSizes: [100, 150, 200],
      width: 1000,
      align: 'center',
      x: 1820,
      y: 370,
    },
  },
  {
    name: `Have a Shitty Day`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/bubble-shitty-day.png',
    gens: [1, 2, 3, 4],
    isSpeechBubble: true,
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -100,
    },
    position2: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Congrats!`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/bubble-congrats.png',
    gens: [1, 2, 3, 4],
    isSpeechBubble: true,
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -100,
    },
    position2: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Thanks!`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/bubble-thanks.png',
    gens: [1, 2, 3, 4],
    isSpeechBubble: true,
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -100,
    },
    position2: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Shitty!`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/shitty-full.png',
    foregroundImage: '/memes/fourth-litter/shitty.png',
    backgroundImage: '/memes/fourth-litter/going-up.png',
    gens: [1, 2, 3, 4],
    position1: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -100,
    },
    position2: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
    position4: {
      width: 4450,
      height: 4450,
      x: -1400,
      y: -350,
    },
  },
  {
    name: `Do Something`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/do-something.png',
    disableAccessory: true,
    forceBackground: 'White',
    gens: [4],
    position4: {
      width: 3000,
      height: 3000,
      x: -775,
      y: -200,
    },
  },
  {
    name: `This is Fine`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/this-is-fine-full.png',
    foregroundImage: '/memes/fourth-litter/this-is-fine-fg.png',
    backgroundImage: '/memes/fourth-litter/this-is-fine-bg.png',
    gens: [4],
    position4: {
      width: 2800,
      height: 2800,
      x: 0,
      y: 120,
    },
  },
  {
    name: `Change My Mind`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/change-mind-blank-full.png',
    foregroundImage: '/memes/fourth-litter/change-mind-blank.png',
    backgroundImage: '/memes/fourth-litter/change-mind-bg.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 3000,
      height: 3000,
      x: -760,
      y: -130,
    },
    editable: {
      defaultFontSize: 100,
      fontSizes: [50, 75, 100],
      width: 1000,
      align: 'center',
      x: 1140,
      y: 2070,
    },
  },
  {
    name: `Shittiest NFTS on Algorand`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/change-mind-shitty-full.png',
    foregroundImage: '/memes/fourth-litter/change-mind-shitty.png',
    backgroundImage: '/memes/fourth-litter/change-mind-bg.png',
    disableAccessory: true,
    gens: [4],
    position4: {
      width: 3000,
      height: 3000,
      x: -760,
      y: -130,
    },
  },

  ...birthdayMemes,
  ...newYearsMemes,

  {
    name: `Valentine's Day`,
    category: 'Shitty Kitties',
    image: '/memes/third-litter/valentines-day.png',
    disableHeads: false,
    disableClothes: true,
    disableAccessory: true,
    gens: [3],
  },
  {
    name: `Valentine's Day`,
    category: 'Shitty Kitties',
    image: '/memes/third-litter/valentines-day-2.png',
    disableHeads: false,
    disableClothes: true,
    disableAccessory: true,
    gens: [3],
  },
  {
    name: `Valentine's Day`,
    category: 'Shitty Kitties',
    image: '/memes/third-litter/valentines-day-3.png',
    disableHeads: false,
    disableClothes: true,
    disableAccessory: true,
    gens: [3],
  },
  {
    name: `St. Patrick's Day`,
    category: 'Shitty Kitties',
    image: '/memes/third-litter/st-patricks-day.png',
    disableHeads: true,
    disableClothes: true,
    disableAccessory: true,
    gens: [3],
  },

  {
    name: `SHEP Eyes`,
    category: 'SHEP',
    image: '/memes/fourth-litter/collabs/shep/shep-eyes-1.png',
    foregroundImage: '/memes/fourth-litter/collabs/shep/shep-eyes-1.png',
    disableEyes: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -800,
      y: -350,
    },
    pfpModeAvailable: true,
  },
  {
    name: `SHEP Glasses`,
    category: 'SHEP',
    image: '/memes/fourth-litter/collabs/shep/shep-eyes-2.png',
    foregroundImage: '/memes/fourth-litter/collabs/shep/shep-eyes-2.png',
    disableEyes: true,
    gens: [4],
    position4: {
      width: 4450,
      height: 4450,
      x: -800,
      y: -350,
    },
  },
];

export const OTHER_MEMES = [
  '/memes/other/wen.png',
  '/memes/other/anakin.png',
  '/memes/other/cannot-hold-it.png',
  '/memes/other/change-my-mind.png',
  '/memes/other/choices.png',
  '/memes/other/crying.png',
  '/memes/other/find-difference.png',
  '/memes/other/just-do-it.png',
  '/memes/other/not-a-kitty.png',
  '/memes/other/old-mad-kitty.png',
  '/memes/other/poke-transparent.png',
  '/memes/other/poke.png',
  '/memes/other/change-my-mind-shittiest.png',
  '/memes/other/shitty-1.png',
  '/memes/other/shitty-2.png',
  '/memes/other/shitty-transparent.png',
  '/memes/other/this-is-fine.png',
  '/memes/other/waiting.png',
  '/memes/other/uhhh.gif',
  '/memes/other/take-my-money.png',
  '/memes/other/shit-in-garden.webp',
  '/memes/other/6-tokens-for-1.webp',
  '/memes/other/vm-tokens.webp',
  '/memes/other/kitty-with-sign.png',
  '/memes/other/kitty-with-sign-empty.png',
  '/memes/other/uno-reverse.png',
  '/memes/other/to-the-moon.png',
];

export default MEMES;
