const newYearsMemes = [
  // works for all years
  {
    name: `Shitty New Year`,
    category: 'Shitty Kitties',
    image: '/memes/first-litter/new-years-full.png',
    foregroundImage: '/memes/first-litter/new-years-fg.png',
    backgroundImage: '/memes/first-litter/new-years-bg.png',
    gens: [1],
    disableHeads: true,
    disableAccessory: true,
    position1: {
      width: 3500,
      height: 3500,
      x: -275,
      y: -175,
    },
  },
  {
    name: `Shitty New Year`,
    category: 'Shitty Kitties',
    image: '/memes/second-litter/new-years-full.png',
    foregroundImage: '/memes/second-litter/new-years-fg.png',
    backgroundImage: '/memes/second-litter/new-years-bg.png',
    gens: [2],
    disableHeads: true,
    disableAccessory: true,
    position2: {
      width: 3000,
      height: 3000,
      x: 0,
      y: 0,
    },
  },
  {
    name: `Shitty New Year`,
    category: 'Shitty Kitties',
    image: '/memes/third-litter/new-years-full.png',
    foregroundImage: '/memes/third-litter/new-years-fg.png',
    backgroundImage: '/memes/third-litter/new-years-bg.png',
    gens: [3],
    disableHeads: true,
    disableAccessory: true,
    position3: {
      width: 3000,
      height: 3000,
      x: 0,
      y: 0,
    },
  },
  {
    name: `Shitty New Year`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/new-years-full.png',
    foregroundImage: '/memes/fourth-litter/new-years-fg.png',
    backgroundImage: '/memes/fourth-litter/new-years-bg.png',
    gens: [4],
    disableHeads: true,
    disableAccessory: true,
    position4: {
      width: 3000,
      height: 3000,
      x: 0,
      y: 0,
    },
  },

  // 2023
  {
    name: 'Shitty New Year: 2023',
    category: 'Shitty Kitties',
    image: '/memes/third-litter/new-years-2023.png',
    disableHeads: true,
    disableClothes: false,
    disableAccessory: true,
    gens: [3],
  },
  {
    name: 'Shitty New Year: 2023',
    category: 'Shitty Kitties',
    image: '/memes/third-litter/new-years-2023-2.png',
    disableHeads: true,
    disableClothes: false,
    disableAccessory: true,
    gens: [3],
  },
];

export default newYearsMemes;
