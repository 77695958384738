const birthdayMemes = [
  {
    name: `Happy Birthday`,
    category: 'Shitty Kitties',
    image: '/memes/first-litter/birthday.png',
    gens: [1],
    isSpeechBubble: true,
    disableHeads: true,
    disableAccessory: true,
    position1: {
      width: 4500,
      height: 4500,
      x: -1450,
      y: -160,
    },
  },
  {
    name: `Happy Birthday`,
    category: 'Shitty Kitties',
    image: '/memes/second-litter/birthday.png',
    gens: [2],
    isSpeechBubble: true,
    disableHeads: true,
    disableAccessory: true,
    position2: {
      width: 3500,
      height: 3500,
      x: -800,
      y: -400,
    },
  },
  {
    name: `Happy Birthday`,
    category: 'Shitty Kitties',
    image: '/memes/third-litter/birthday.png',
    gens: [3],
    isSpeechBubble: true,
    disableHeads: true,
    position3: {
      width: 3000,
      height: 3000,
      x: -650,
      y: 0,
    },
  },
  {
    name: `Happy Birthday`,
    category: 'Shitty Kitties',
    image: '/memes/fourth-litter/birthday.png',
    gens: [4],
    isSpeechBubble: true,
    disableHeads: true,
    disableAccessory: true,
    disableMouth: true,
    position4: {
      width: 4500,
      height: 4500,
      x: -1390,
      y: -350,
    },
  },
];

export default birthdayMemes;
