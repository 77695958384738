const assets = [
  976690696, 976702280, 976703873, 976705822, 976708437, 976711976, 976714282, 976715305, 976716441, 976718708, 976719679, 980964468, 981950746,
  981951589, 981952361, 981955447, 981956105, 981957593, 981958330, 981959114, 981961038, 981961797, 981962933, 984042976, 984044542, 984045550,
  984046365, 984052574, 984053772, 984054383, 984854772, 984857526, 984858939, 984859559, 984875797, 984877027, 984879011, 984881434, 984883558,
  985723521, 985724713, 985725116, 985725592, 985725946, 985726481, 985726867, 985727309, 985727671, 985728903, 985730079, 985730370, 987167188,
  987167791, 987168400, 987168970, 987170519, 987172188, 987172910, 987173853, 989382321, 989382671, 989383227, 989384402, 989384851, 989385234,
  989385656, 989385947, 989386816, 989387535, 989388185, 989415432, 989416198, 989417365, 989418309, 989539746, 989540090, 989540413, 989541073,
  989541538, 989541909, 989542303, 989542654, 989543006, 994227640, 994228116, 994228579, 994229122, 994229544, 994230132, 994231601, 994232997,
  995261548, 995262230, 995262866, 995263498, 995264126, 995264679, 995265452, 995267341, 995267958, 995268813, 995270597, 995271671, 995272827,
  995273461, 996730737, 996734978, 996738384, 996739386, 996740878, 996745895, 996747001, 996747823, 996749223, 996749806, 996751055, 996752588,
  996753283, 996754490, 996755182, 996756069, 996756654, 996757202, 996757728, 996758904, 1017595107, 1026139805, 1026140386, 1026149820, 1028522555,
  1029988915, 1031934544, 1034667123, 1036048924, 1041061146, 1058753256, 2223170979, 2226961624, 2244689679, 2264097881, 2264100978, 2266109398,
  2266120106, 2266139256, 2267784072, 2267788044, 2267788995, 2267790106, 2267791878, 2267792828, 2267793787, 2267794611, 2267795917, 2270775780,
  2270776638, 2270778828, 2270779583, 2270780465, 2270781474, 2270782110, 2270783322, 2270783972, 2270785847, 2270786562, 2270787751, 2270788591,
  2270790127, 2270790905, 2270791473, 2270797934, 2270799777, 2270800647, 2270801450, 2270801948, 2270803125, 2270804372, 2270805239, 2270805869,
  2270806444, 2270807357, 2270808858, 2270810804, 2270811843, 2270815863, 2270816781, 2270817466, 2270818398, 2270819574, 2270820552, 2270821304,
  2270823947, 2270825148, 2270839709, 2270840633, 2271718764, 2273216150, 2273231301, 2273233325, 2274205989, 2274425328, 2276138429, 2276433458,
  2276437067, 2278284385, 2284083566, 2284089538, 2284290279, 2285270532, 2288518968, 2289424992, 2296076295, 2296076959, 2296078446, 2296079057,
  2296079755, 2296080445, 2296081669, 2296082216, 2296082939, 2296083727, 2296085253, 2296085924, 2296086593, 2296087166, 2296088228, 2296089536,
  2296090196, 2296090970, 2296093560, 2296094994, 2296096083, 2296097704, 2296100220, 2312612513, 2312862726, 2312864901, 2312866766, 2328309025,
  2328314888, 2328316365, 2328317154, 2353654725, 2358710702, 2381615156, 2402952574, 2441691219, 2470316648, 2470322135, 2470328381, 2470342246,
  2470356257, 2470361497, 2470364226, 2470371639, 2470374880, 2470394447, 2470399909, 2470404073, 2470407936, 2470410745, 2470414176, 2470421146,
  2470425569, 2470428112, 2470431238, 2470434288, 2470436438, 2470443564, 2470447404, 2470451748, 2470455421, 2470469993, 2470472983, 2470475575,
  2470480937, 2470484440, 2470486573, 2470581642, 2470584881, 2470586755, 2470589581, 2470591497, 2470592995, 2470595951, 2470598013, 2470601422,
  2470604543, 2470607328, 2470611292, 2509073559, 2509076968, 2509080702, 2509083369, 2509086403, 2509089116, 2509091832, 2509137084, 2509140858,
  2515297578, 2515565520, 2517022478, 2517026318, 2517028526, 2517033122, 2531204736, 2531208953, 2531217291, 2531221955, 2531224605, 2531228505,
  2577716029, 2577720077, 2577723966, 2577726595, 2577728476, 2577732205, 2577734462, 2577739779, 2577742281, 2577744551, 2577746510, 2577911006,
  2577913764, 2577916966, 2577919409, 2577959256, 2577964978, 2577968488, 2577971525, 2581466470, 2622554439, 2622560770, 2622568920, 2622571141,
  2622572706, 2624835223, 2624840585, 2624843814, 2624846986, 2624854459, 2624856506, 2624858767, 2624861511, 2624864367, 2624866510, 2624869033,
  2624873201, 2624878255, 2624882467, 2624886775, 2624889963, 2624895626, 2624902240, 2624908034, 2624914596,
];

export default assets;
