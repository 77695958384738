import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import useNfd from '../hooks/useNfd';

function WalletAddress({ address }) {
  const { getNfd } = useNfd();
  const [wallet, setWallet] = useState(null);

  useEffect(() => {
    if (!address) return;

    (async () => {
      try {
        const nfd = await getNfd(address);
        if (nfd) {
          return setWallet(get(nfd, 'name'));
        }
        setWallet(`${address?.slice(0, 4)}...${address?.slice(-4)}`);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [address]);

  return <span>{wallet}</span>;
}

export default WalletAddress;
