import Link from 'next/link';

const ChromeExtensionBanner = () => {
  return (
    <div className='hidden lg:flex items-center p-2 bg-gradient-to-r from-primary-700 to-primary-300'>
      <div className='flex items-center gap-2'>
        <div className='w-6 h-6'>
          <svg viewBox='0 0 128 128' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <rect width='128' height='128' rx='20' fill='#151515' />
            <path
              d='M60.14 20.7C60.46 21.3 60.64 21.84 60.68 22.32C60.76 22.8 60.8 23.5 60.8 24.42V57.72C60.8 60.48 60.04 62.1 58.52 62.58C57.76 62.82 56.8 62.94 55.64 62.94C54.48 62.94 53.56 62.84 52.88 62.64C52.2 62.44 51.68 62.2 51.32 61.92C50.96 61.64 50.68 61.24 50.48 60.72C50.28 60.04 50.18 59 50.18 57.6V37.5C49.18 38.74 47.72 40.72 45.8 43.44C43.88 46.12 42.66 47.8 42.14 48.48C41.62 49.16 41.26 49.64 41.06 49.92C40.86 50.16 40.36 50.52 39.56 51C38.8 51.44 37.96 51.66 37.04 51.66C36.16 51.66 35.34 51.46 34.58 51.06C33.86 50.62 33.34 50.2 33.02 49.8L32.54 49.14C31.74 48.14 30.16 46.02 27.8 42.78C25.44 39.5 24.16 37.74 23.96 37.5V57.72C23.96 58.64 23.92 59.34 23.84 59.82C23.8 60.26 23.62 60.76 23.3 61.32C22.7 62.4 21.14 62.94 18.62 62.94C16.18 62.94 14.66 62.4 14.06 61.32C13.74 60.76 13.54 60.24 13.46 59.76C13.42 59.28 13.4 58.56 13.4 57.6V24.3C13.4 23.38 13.42 22.7 13.46 22.26C13.54 21.78 13.74 21.24 14.06 20.64C14.66 19.6 16.22 19.08 18.74 19.08C19.82 19.08 20.74 19.22 21.5 19.5C22.3 19.74 22.82 20 23.06 20.28L23.42 20.64L37.04 38.52C43.84 29.52 48.38 23.58 50.66 20.7C51.34 19.62 52.94 19.08 55.46 19.08C58.02 19.08 59.58 19.62 60.14 20.7Z'
              fill='white'
            />
            <path
              d='M113.14 66.7C113.46 67.3 113.64 67.84 113.68 68.32C113.76 68.8 113.8 69.5 113.8 70.42V103.72C113.8 106.48 113.04 108.1 111.52 108.58C110.76 108.82 109.8 108.94 108.64 108.94C107.48 108.94 106.56 108.84 105.88 108.64C105.2 108.44 104.68 108.2 104.32 107.92C103.96 107.64 103.68 107.24 103.48 106.72C103.28 106.04 103.18 105 103.18 103.6V83.5C102.18 84.74 100.72 86.72 98.8 89.44C96.88 92.12 95.66 93.8 95.14 94.48C94.62 95.16 94.26 95.64 94.06 95.92C93.86 96.16 93.36 96.52 92.56 97C91.8 97.44 90.96 97.66 90.04 97.66C89.16 97.66 88.34 97.46 87.58 97.06C86.86 96.62 86.34 96.2 86.02 95.8L85.54 95.14C84.74 94.14 83.16 92.02 80.8 88.78C78.44 85.5 77.16 83.74 76.96 83.5V103.72C76.96 104.64 76.92 105.34 76.84 105.82C76.8 106.26 76.62 106.76 76.3 107.32C75.7 108.4 74.14 108.94 71.62 108.94C69.18 108.94 67.66 108.4 67.06 107.32C66.74 106.76 66.54 106.24 66.46 105.76C66.42 105.28 66.4 104.56 66.4 103.6V70.3C66.4 69.38 66.42 68.7 66.46 68.26C66.54 67.78 66.74 67.24 67.06 66.64C67.66 65.6 69.22 65.08 71.74 65.08C72.82 65.08 73.74 65.22 74.5 65.5C75.3 65.74 75.82 66 76.06 66.28L76.42 66.64L90.04 84.52C96.84 75.52 101.38 69.58 103.66 66.7C104.34 65.62 105.94 65.08 108.46 65.08C111.02 65.08 112.58 65.62 113.14 66.7Z'
              fill='white'
            />
          </svg>
        </div>

        <div>
          <p className='font-medium'>
            NEW: Minner Memer is a Chrome extension that allows you to easily insert Shitty Kitty memes into your X Posts.
          </p>
        </div>

        <div>
          <Link href='https://chromewebstore.google.com/detail/minner-memer/occeppofeddhonmnifpekgmjcoigcldl'>
            <a className='text-white font-bold border border-white px-2 py-1 rounded-md'>Add to Chrome</a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ChromeExtensionBanner;
