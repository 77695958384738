import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import clsx from 'clsx';
import Link from 'next/link';
import React, { useState } from 'react';
import formatNumber from '../utils/formatNumber';
import Button from './Button';
import Container from './Container';
import ProfileAvatar from './ProfileAvatar';
import Spinner from './Spinner';
import WalletAddress from './WalletAddress';

const TopUsersFeed = () => {
  const [tab, setTab] = useState('totalKitties');

  const query = useQuery({
    queryKey: ['topUsersFeed', tab],
    queryFn: async () => {
      const url = `/api/top-users/list?tab=${tab}`;
      const { data } = await axios.get(url);
      return data;
    },
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
  });
  const isLoading = query.isLoading;
  const users = query.data || [];
  const firstColumn = users.slice(0, 5);
  const secondColumn = users.slice(5, 10);

  const tabs = [
    { value: 'totalKitties', label: 'Total Kitties' },
    { value: 'totalCities', label: 'Total Cities' },
    { value: 'achievementPoints', label: 'Achievement Points' },
    { value: 'adventuresCompleted', label: 'Adventures' },
    { value: 'bountiesCompleted', label: 'Bounties' },
    { value: 'discordMessages', label: 'Discord Messages' },
    { value: 'discordReactions', label: 'Discord Reactions' },
    { value: 'hypePosts', label: '#ShittyHype Posts' },
  ];

  return (
    <div className='mb-12 lg:mb-24 mx-auto'>
      <Container>
        <div className='mb-10'>
          <div>
            <h2 className='text-2xl font-extrabold text-neutral-300 md:text-4xl'>Top 10 Holders</h2>
            <p className='mt-2 text-lg text-neutral-500'>Data tracked since 6/18/2024</p>

            <div className='mt-6'>
              <div className='hidden sm:flex gap-3 flex-wrap max-w-[700px]'>
                {tabs.map(({ value, label }) => (
                  <Button key={value} className='!px-4 !py-2' theme={tab === value ? 'primary' : 'outline-white'} onClick={() => setTab(value)}>
                    {label}
                  </Button>
                ))}
              </div>
              <div className='sm:hidden'>
                <select
                  className='order-1 md:order-2 w-full md:w-auto form-input bg-gray-900 border-0 rounded-md py-3.5 focus:ring-2 focus:ring-white pr-10 text-lg'
                  value={tab}
                  onChange={(e) => setTab(e.target.value)}
                >
                  {tabs.map(({ value, label }) => (
                    <option key={value} value={value}>
                      {label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className='h-[350px] flex items-center justify-center'>
            <Spinner />
          </div>
        ) : (
          <div className='flex md:flex-row flex-col gap-4 w-full'>
            <div className='flex flex-col gap-4 w-full'>
              {firstColumn.map((user, index) => (
                <Item key={user.address} user={user} index={index} />
              ))}
            </div>
            <div className='flex flex-col gap-4 w-full'>
              {secondColumn.map((user, index) => (
                <Item key={user.address} user={user} index={index + 5} />
              ))}
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

const Item = ({ user, index, numLabel }) => {
  const themes = ['text-yellow-500', 'text-silver-500', 'text-orange-400'];
  const theme = themes[index] || 'text-gray-500';

  return (
    <Link href={`/profile/${user?.address}`}>
      <a className='flex flex-col lg:flex-row lg:items-center justify-between px-6 py-4 bg-gray-900 rounded-md w-full ring-2 ring-transparent hover:ring-primary-500 transition duration-200'>
        <div className='gap-x-6 flex items-center'>
          <div className='flex gap-4'>
            {index > 2 ? (
              <div className='border border-gray-700 rounded-md w-[50px] h-[50px] flex items-center justify-center'>
                <p className='text-2xl font-bold text-gray-500'>{index + 1}</p>
              </div>
            ) : (
              <div className='w-[50px] h-[50px] flex items-center justify-center'>
                <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' className={clsx('w-10', theme)}>
                  <path
                    d='M400 0H176c-26.5 0-48.1 21.8-47.1 48.2c.2 5.3 .4 10.6 .7 15.8H24C10.7 64 0 74.7 0 88c0 92.6 33.5 157 78.5 200.7c44.3 43.1 98.3 64.8 138.1 75.8c23.4 6.5 39.4 26 39.4 45.6c0 20.9-17 37.9-37.9 37.9H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H357.9C337 448 320 431 320 410.1c0-19.6 15.9-39.2 39.4-45.6c39.9-11 93.9-32.7 138.2-75.8C542.5 245 576 180.6 576 88c0-13.3-10.7-24-24-24H446.4c.3-5.2 .5-10.4 .7-15.8C448.1 21.8 426.5 0 400 0zM48.9 112h84.4c9.1 90.1 29.2 150.3 51.9 190.6c-24.9-11-50.8-26.5-73.2-48.3c-32-31.1-58-76-63-142.3zM464.1 254.3c-22.4 21.8-48.3 37.3-73.2 48.3c22.7-40.3 42.8-100.5 51.9-190.6h84.4c-5.1 66.3-31.1 111.2-63 142.3z'
                    fill='currentColor'
                  />
                </svg>
              </div>
            )}
          </div>

          <div className='flex items-center'>
            <div className='flex items-center mr-4'>
              <ProfileAvatar width={40} address={user?.address} />
            </div>
            <div className='flex flex-col'>
              <p className='text-lg md:text-xl truncate overflow-ellipsis w-[150px] sm:w-auto'>
                <WalletAddress address={user?.address} />
              </p>
              <p className='font-bold lg:hidden'>{formatNumber(user?.value)}</p>
            </div>
          </div>
        </div>

        <div className='hidden lg:block'>
          <p className='text-2xl font-bold'>{formatNumber(user?.value)}</p>
        </div>
      </a>
    </Link>
  );
};

export default TopUsersFeed;
